import React, { useState } from 'react';
import axios from 'utils/axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
//images
import bgOvelayImg from 'assets/images/contactUS/contact-us.webp';
//icons
import { IoMdArrowDropdown } from 'react-icons/io'
//Styles
import './Contact.css';

const styles = {
    darkOverlay: {
        position: 'absolute',
        width: "100%",
        height: 769,
        background: "rgba(3, 3, 3, 0.5)",
        zIndex: 1,
    },
    bgOverlay: {
        position: 'absolute',
        zIndex: 0,
        backgroundImage: `url(${bgOvelayImg})`,
        backgroundSize: 'cover',
        backRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        width: '100%',
        height: 769,
    },
    icon: {
        position: 'absolute',
        fontSize: 40,
        color: '#144460',
        right: 10,
    }
}

const validationsSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is a required field'),
    last_name: Yup.string().required('Last Name is a required field'),
    email: Yup.string().email('Invalid email').required('Email is a required field'),
    help_option: Yup.string().required('Must select an option'),
    message: Yup.string().required('Message is a required field'),
});

export const ContactUs = () => {

    //State
    const [loading, setloading] = useState(false);

    //Formik
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            help_option: "",
            message: ""
        },
        validationSchema: validationsSchema,
        onSubmit: async (values, { resetForm }) => {

            //loading status
            setloading(true);

            try {
                const result = await axios.post('contact/sendMessage', values);

                if (result.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Message Sent!',
                        text: result.data.message,
                    });
                    resetForm({});
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: result.data.message,
                    });
                }
            } catch (error) {
                console.log('Error: ', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }

            //loading status
            setloading(false);
        },
    });

    return (
        <div className="contact-us">
            <div style={styles.darkOverlay}></div>
            <div style={styles.bgOverlay}></div>
            <div className="content">
                <div className="cont-left">
                    <h1 className="title">Let’s Have a Chat</h1>
                    <p className="description">
                        Need to reach the CharityLabs team? You know what to do.
                    </p>
                </div>
                <div className="cont-right">
                    <form className="form-contact" onSubmit={formik.handleSubmit}>
                        <div className="cont-client-name">
                            <div className="name-gap">
                                <input type="text"
                                    name="first_name"
                                    className="contact-input"
                                    onChange={formik.handleChange}
                                    value={formik.values.first_name}
                                    placeholder="First Name"
                                />
                                {formik.errors.first_name && formik.touched.first_name ? (
                                    <div className="msg-error">{formik.errors.first_name}</div>
                                ) : null}
                            </div>
                            <div className="name-gap">
                                <input type="text"
                                    name="last_name"
                                    className="contact-input"
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                    placeholder="Last Name"
                                />
                                {formik.errors.last_name && formik.touched.last_name ? (
                                    <div className="msg-error">{formik.errors.last_name}</div>
                                ) : null}
                            </div>
                        </div>
                        <input name="email"
                            className="contact-input"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            placeholder="Email address"
                        />
                        {formik.errors.email && formik.touched.email ? (
                            <div className="msg-error">{formik.errors.email}</div>
                        ) : null}
                        <div className="row">
                            <select name="help_option"
                                className={`contact-input select ${formik.values.help_option === "" ? 'placeholder' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.help_option}
                            >
                                <option value="" hidden={true} disabled={true}>How can we help?</option>
                                <option value="Media Inquiries">Media Inquiries</option>
                                <option value="Partnership Requests">Partnership Requests</option>
                                <option value="Tech Support">Tech Support</option>
                                <option value="Other">Other</option>
                            </select>
                            <IoMdArrowDropdown style={styles.icon} />
                        </div>
                        {formik.errors.help_option && formik.touched.help_option ? (
                            <div className="msg-error">{formik.errors.help_option}</div>
                        ) : null}
                        <textarea type="text"
                            name="message"
                            className="contact-input message"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            placeholder="Message"
                        />
                        {formik.errors.message && formik.touched.message ? (
                            <div className="msg-error">{formik.errors.message}</div>
                        ) : null}
                        <button type="submit" className="btn-submit" disabled={loading}>
                            {loading ? (
                                <Loader
                                    type="ThreeDots"
                                    color="#FFF"
                                    height={30}
                                    width={50}
                                />
                            ) : ('Submit')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
