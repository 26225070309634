import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './error404.module.css';

export const Error404 = () => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <div className={styles.error404}>
            <div className={styles.content}>
                <h2 className={styles.title}>Error 404</h2>
                <h2 className={styles.subtitle}>Sorry, this page isn't available.</h2>
                <p>Looks like this page is missing, or the page may have been removed.</p>
                <button className={styles.btn_go_back} onClick={handleGoBack} >Go Back</button>
            </div>
        </div>
    );
}
