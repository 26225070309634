import React from 'react'

export const Cause = (props) => {

    const { cause, setCause, setStep } = props;

    const onSelected = (option, value) => {
        let count = 0;
        for (const property in cause) {
            if (cause[property] === true) {
                count++;
            }
        }
        if (count >= 3) {
            setCause({
                ...cause,
                [option]: false,
            });
        } else {
            setCause({
                ...cause,
                [option]: !value,
            });
        }
    }

    const nextStep = () => {
        let count = 0;
        for (const property in cause) {
            if (cause[property] === true) {
                count++;
            }
        }
        if (count >= 1 && count <= 3) {
            setStep(3);
        }
    }

    const backStep = () => {
        setStep(1);
    }

    return (
        <div className="content">

            <h1 className="question">Select up to 3 causes.</h1>

            <div className="multiple-options">
                <div className={`option ${cause.clean_energy === true ? 'active' : ''}`}
                    onClick={() => onSelected('clean_energy', cause.clean_energy)}
                >Clean Energy/Resources</div>
                <div className={`option ${cause.environmental_education === true ? 'active' : ''}`}
                    onClick={() => onSelected('environmental_education', cause.environmental_education)}
                >Environmental Education</div>
                <div className={`option ${cause.protecting_habitats === true ? 'active' : ''}`}
                    onClick={() => onSelected('protecting_habitats', cause.protecting_habitats)}
                >Protecting Habitats</div>
                <div className={`option ${cause.deforestation === true ? 'active' : ''}`}
                    onClick={() => onSelected('deforestation', cause.deforestation)}
                >Deforestation</div>
                <div className={`option ${cause.pollution === true ? 'active' : ''}`}
                    onClick={() => onSelected('pollution', cause.pollution)}
                >Pollution</div>
                <div className={`option ${cause.environmental_advocacy === true ? 'active' : ''}`}
                    onClick={() => onSelected('environmental_advocacy', cause.environmental_advocacy)}
                >Environmental Advocacy</div>
                <div className={`option ${cause.recycling === true ? 'active' : ''}`}
                    onClick={() => onSelected('recycling', cause.recycling)}
                >Recycling</div>
                <div className={`option ${cause.oceanic_wildlife === true ? 'active' : ''}`}
                    onClick={() => onSelected('oceanic_wildlife', cause.oceanic_wildlife)}
                >Oceanic Wildlife</div>
                <div className={`option ${cause.lakes_rivers_and_streams === true ? 'active' : ''}`}
                    onClick={() => onSelected('lakes_rivers_and_streams', cause.lakes_rivers_and_streams)}
                >Lakes, Rivers &amp; Streams</div>
                <div className={`option ${cause.global_warming === true ? 'active' : ''}`}
                    onClick={() => onSelected('global_warming', cause.global_warming)}
                >Global Warming</div>
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
