import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
//Styles
import './Navbar.css';
//images
import logo from 'assets/images/logo/logo_charitylabs.webp';

const styles = {
    navbarHome: {
        background: '#E6E7E8'
    }
}

export const Navbar = () => {

    const location = useLocation();

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    let home = false;
    if (location.pathname === "/") {
        home = true;
    }

    return (
        <div className="navbar" style={home === true ? styles.navbarHome : {}}>
            <div className="nav-container">
                
                <div className="cont-logo">
                    <Link to="/" ><img src={logo} className="logo" alt="CharityLabs" /></Link>
                    <span className="credits"><a href="https://www.97thfloor.com" target="_blank">Powered By 97th Floor</a></span>
                </div>

                <div className='menu-icon' onClick={handleClick}>
                    {click ? <FaTimes className="btn-menu" /> : <FaBars className="btn-menu" />}
                </div>

                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="/mission" 
                        className="nav-links" 
                        onClick={closeMobileMenu}>
                            Mission
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/findYourCharity" 
                        className="nav-links" 
                        onClick={closeMobileMenu}>
                            Find your Charity
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={{
                            pathname: "/questionnaire",
                            state: {
                                applied: true
                              }
                        }} 
                        className="btn-take-quiz" 
                        onClick={closeMobileMenu}>
                            Take the Charity Quiz
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}
