import React from 'react';
import { Card } from '../card/Card';
//images
import climateChange from 'assets/images/home/cards/deforestation.webp';


import homeless from 'assets/images/home/cards/homeless.webp';
import endangeredInsects from 'assets/images/home/cards/endangeredInsects.webp';
import pollutionInAsia from 'assets/images/home/cards/pollutionInAsia.webp';
import LGBTQIATeens from 'assets/images/home/cards/LGBTQIATeens.webp';
import abusedPets from 'assets/images/home/cards/abusedPets.webp';
import womensRight from 'assets/images/home/cards/womensRights.webp';
import racialEquality from 'assets/images/home/cards/racialEquality.webp';
import education from 'assets/images/home/cards/education.webp';
//Styles
import styles from './CardList.module.css';

export const CardList = () => {
    return (
        <div className={styles.sec_change_world}>
            <div className={styles.content}>
                <h1 className={styles.title}>How will you change the world?</h1>
                <div className={styles.cont_cards}>

                    <Card
                        image={climateChange}
                        title={'Climate Change'}
                    />
                    <Card
                        image={womensRight}
                        title={'Women’s Rights'}
                    />
                    <Card
                        image={racialEquality}
                        title={'Racial Equality'}
                    />
                    <Card
                        image={education}
                        title={'Education'}
                    />
                    <Card
                        image={LGBTQIATeens}
                        title={'LGBTQIA+'}
                    />
                    <Card
                        image={abusedPets}
                        title={'Animal Protection'}
                    />

                </div>
            </div>
        </div>
    )
}

export default CardList;