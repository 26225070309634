import React, { useState, useEffect } from 'react';
import styles from '../MobileFilters.module.css';
import Select from 'react-select';
import { enviroment_area, enviroment_cause } from '../FilterOptions';

export const EnviromentFilterMobile = (props) => {

    const { applyFilters, resetFilters, filters } = props;

    const [area, setArea] = useState([]);
    const [cause, setCause] = useState([]);

    useEffect(() => {
        refresh(filters);
    }, [filters]);

    const refresh = (filters) => {

        let type_array = [];
        enviroment_area.forEach(el => {
            for (const property in filters) {
                if(el.value === property){
                    type_array.push(el);
                    break;
                }
            }
        });
        setArea(type_array);

        let cause_array = [];
        enviroment_cause.forEach(el => {
            for (const property in filters) {
                if(el.value === property){
                    cause_array.push(el);
                    break;
                }
            }
        });
        setCause(cause_array);

    }

    const handleReset = () => {
        resetFilters();
        setArea([]);
        setCause([]);
    }

    const handleApply = () => applyFilters([...area, ...cause]);

    return (
        <>
            <span className={styles.filter_question}>Additional Filters</span>

            <div className={styles.cont_actions}>
                <Select
                    isMulti
                    options={enviroment_area}
                    value={area}
                    onChange={setArea}
                    className={styles.multi_select}
                    placeholder={"Area"}
                />
                <Select
                    isMulti
                    options={enviroment_cause}
                    value={cause}
                    onChange={setCause}
                    className={styles.multi_select}
                    placeholder={"Cause"}
                />
            </div>

            <button className={styles.btn_apply} onClick={handleApply}>Apply Filters</button>
            <span className={styles.btn_reset} onClick={handleReset}>Reset All</span>
        </>
    )
}
