import React from 'react';
//Components
import { AnimalsFilters } from './animalsFilter/AnimalsFilters';
import { EnviromentFilters } from './enviromentFilters/EnviromentFilters';
import { PeopleFilters } from './peopleFilter/PeopleFilters';
//Styles
import styles from './Sidebar.module.css';
//icons
import { AiOutlineSearch } from 'react-icons/ai';

const customStyles = {
    icon: {
        position: 'absolute',
        fontSize: 28,
        color: '#707070',
        marginLeft: 11,
    }
};

export const Sidebar = (props) => {

    const { search, onSearch, onFilter, filters } = props;

    return (
        <div className={`${styles.sidebar}`}>
            <div className={`${styles.sidebar_content}`}>

                <div className={styles.sidebar_row}>
                    <AiOutlineSearch style={customStyles.icon} />
                    <input
                        type="text"
                        className={styles.sidebar_search}
                        value={search}
                        onChange={onSearch}
                        placeholder="Keywords"
                    />
                </div>

                <PeopleFilters filters={filters} onFilter={onFilter} />

                <AnimalsFilters filters={filters} onFilter={onFilter} />

                <EnviromentFilters filters={filters} onFilter={onFilter} />

            </div>
        </div>
    )
}
