import React, { useState, useEffect } from 'react';
import { AnimalsQuiz } from './quiz/animals/AnimalsQuiz';
import { EnviromentQuiz } from './quiz/enviroment/EnviromentQuiz';
import PeopleQuiz from './quiz/people/PeopleQuiz';
import { QuizType } from './quizType/QuizType';
//Styles
import './Questionnaire.css';
import { useLocation } from 'react-router-dom';

export const Questionnaire = () => {

    const location = useLocation();

    //State
    const [type, setType] = useState({
        people: false,
        animals: false,
        enviroment: false,
    });

    useEffect(() => {
        resetQuiz();
    }, [location])

    const selectOption = (option) => {
        if (option === 'people') {
            setType({
                people: true,
                animals: false,
                enviroment: false,
            });
        } else if (option === 'animals') {
            setType({
                people: false,
                animals: true,
                enviroment: false,
            });
        } else if (option === 'enviroment') {
            setType({
                people: false,
                animals: false,
                enviroment: true,
            });
        }
    }

    const resetQuiz = () => {
        setType({
            people: false,
            animals: false,
            enviroment: false,
        });
    }

    return (
        <div className="quiz" >

            {/* Select Quiz Type */}
            {type.people === false && type.animals === false && type.enviroment === false ? (
                <QuizType selectOption={selectOption} />
            ) : null}

            {/* People Quiz */}
            {type.people === true ? (
                <PeopleQuiz resetQuiz={resetQuiz} />
            ) : null}

            {/* Enviroment Quiz */}
            {type.animals === true ? (
                <AnimalsQuiz resetQuiz={resetQuiz} />
            ) : null}

            {/* Animals Quiz */}
            {type.enviroment === true ? (
                <EnviromentQuiz resetQuiz={resetQuiz} />
            ) : null}

        </div>
    )
}
