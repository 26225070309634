import React, { useState, useEffect } from 'react'
import { Results } from '../../results/Results';
import { Age } from './questions/Age';
import { Cause } from './questions/Cause';
import { Location } from './questions/Location';

export const PeopleQuiz = (props) => {

    const { resetQuiz } = props;

    const [step, setStep] = useState(1);
    const [age, setAge] = useState({
        children: false,
        teenagers: false,
        adults: false,
        elderly: false
    });
    const [locations, setLocations] = useState({});
    const [cause, setCause] = useState({
        education: false,
        food_and_water: false,
        healthcare: false,
        community_development: false,
        bullying_and_violence: false,
        minorities_and_racial_injustice: false,
        lgbtqia: false,
        military_and_police: false,
        women: false,
        disaster_recovery: false,
        human_trafficking: false,
        displacement: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const cleanState = () => {
        //clean age
        setAge({});

        //clean locations
        setLocations(null);

        //clean cause options
        setCause({});

        //Reset the Step
        setStep(1);

        //Reset Quiz
        resetQuiz()
    }

    return (
        <>
            {step === 1 ? (
                <Age age={age} setAge={setAge} setStep={setStep} {...props} />
            ) : null}
            {step === 2 ? (
                <Location locations={locations} setLocations={setLocations} setStep={setStep} />
            ) : null}
            {step === 3 ? (
                <Cause cause={cause} setCause={setCause} setStep={setStep} />
            ) : null}
            {step === 4 ? (
                <Results filters={{...age, ...locations, ...cause}} setStep={setStep} cleanState={cleanState} />
            ) : null}
        </>
    )
}

export default PeopleQuiz;
