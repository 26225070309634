import React, { useState, useEffect } from 'react';
import styles from '../MobileFilters.module.css';
import Select from 'react-select';
import { animals_type, animals_cause } from '../FilterOptions';

export const AnimalsFilterMobile = (props) => {

    const { applyFilters, resetFilters, filters } = props;

    const [types, setTypes] = useState([]);
    const [cause, setCause] = useState([]);

    useEffect(() => {
        refresh(filters);
    }, [filters]);

    const refresh = (filters) => {

        let type_array = [];
        animals_type.forEach(el => {
            for (const property in filters) {
                if(el.value === property){
                    type_array.push(el);
                    break;
                }
            }
        });
        setTypes(type_array);

        let cause_array = [];
        animals_cause.forEach(el => {
            for (const property in filters) {
                if(el.value === property){
                    cause_array.push(el);
                    break;
                }
            }
        });
        setCause(cause_array);

    }

    const handleReset = () => {
        resetFilters();
        setTypes([]);
        setCause([]);
    }

    const handleApply = () => applyFilters([...types, ...cause]);

    return (
        <>
            <span className={styles.filter_question}>Additional Filters</span>

            <div className={styles.cont_actions}>
                <Select
                    isMulti
                    options={animals_type}
                    value={types}
                    onChange={setTypes}
                    className={styles.multi_select}
                    placeholder={"Animal Type"}
                />
                <Select
                    isMulti
                    options={animals_cause}
                    value={cause}
                    onChange={setCause}
                    className={styles.multi_select}
                    placeholder={"Cause"}
                />
            </div>

            <button className={styles.btn_apply} onClick={handleApply}>Apply Filters</button>
            <span className={styles.btn_reset} onClick={handleReset}>Reset All</span>
        </>
    )
}
