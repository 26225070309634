import React from 'react';
import { Link } from 'react-router-dom';
//Styles
import './Footer.css';
//images
import logo from 'assets/images/logo/logo_white.png';

export const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="content">
                    <div className="cont-left">
                        <div className="cont-logo">
                            <Link to="/"><img src={logo} className="logo" alt="CharityLabs" /></Link>
                        </div>
                        <ul className="cont-links">
                            <li><Link to="questionnaire" className="link" >Take the Charity Quiz</Link></li>
                            <li><Link to="mission" className="link" >Mission</Link></li>
                            <li><Link to="submitCharity" className="link" >Submit A Charity</Link></li>
                            <li><Link to="contactUs" className="link" >Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="cont-right">
                        <Link to="/submitCharity" className="btn-submit">Submit A Charity</Link>
                    </div>
                </div>
            </div>
            <div className="sec-copy-right">
                <div className="content">
                    <span className="credits">Privacy &amp; T&amp;C</span>
                </div>
            </div>
        </>
    )
}
