import React, { useState, useEffect } from 'react';
import WorldMap from 'react-world-map';
import './WordMap.css';

export const WordMap = (props) => {

    //props
    const { getLocations, locations } = props;

    //state
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if(locations){
            refreshLocation(locations);
        }
    }, [locations]);

    const refreshLocation = (locations) => {
        let list = [];
        if(locations.africa === true){
            list.push("af");
        }
        if(locations.north_america){
            list.push("na");
        }
        if(locations.south_america){
            list.push("sa");
        }
        if(locations.europe){
            list.push("eu");
        }
        if(locations.asia){
            list.push("as");
        }
        if(locations.australia){
            list.push("oc");
        }
        setSelected(list);
    }

    const onSelect = (continent) => {
        let list = [...selected];
        let index = list.indexOf(continent);
        if (index >= 0) {
            list.splice(index, 1);
            setSelected(list);
            getLocations(list);
        } else {
            setSelected([...list, continent]);
            getLocations([...list, continent]);
        }
    }

    return (
        <WorldMap
            selected={selected}
            onSelect={onSelect}
            multiple={true}
        />
    );
}

export default WordMap;