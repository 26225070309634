import {useState} from 'react';

export const useModal = (open = false) => {
    const [show, setShow] = useState(open);

    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);

    return {
        show,
        openModal,
        closeModal,
    };

}