export const people_ages = [
    {
        value: "children",
        label: "Children",
    },
    {
        value: "teenagers",
        label: "Teenagers",
    },
    {
        value: "adults",
        label: "Adults",
    },
    {
        value: "elderly",
        label: "Elderly",
    },
];

export const people_locations = [
    {
        value: "north_america",
        label: "North America",
    },
    {
        value: "south_america",
        label: "South America",
    },
    {
        value: "europe",
        label: "Europe",
    },
    {
        value: "africa",
        label: "Africa",
    },
    {
        value: "asia",
        label: "Asia",
    },
    {
        value: "australia",
        label: "Australia",
    },
];

export const people_cause = [
    {
        value: "education",
        label: "Education",
    },
    {
        value: "food_and_water",
        label: "Food & Water",
    },
    {
        value: "healthcare",
        label: "Healthcare",
    },
    {
        value: "community_development",
        label: "Community Development",
    },
    {
        value: "bullying_and_violence",
        label: "Bullying and Violence",
    },
    {
        value: "minorities_and_racial_injustice",
        label: "Minority/racial justice",
    },
    {
        value: "lgbtqia",
        label: "LGBTQIA",
    },
    {
        value: "military_and_police",
        label: "Military & Police",
    },
    {
        value: "women",
        label: "Women",
    },
    {
        value: "disaster_recovery",
        label: "Disaster Recovery",
    },
    {
        value: "human_trafficking",
        label: "Human Trafficking",
    },
    {
        value: "displacement",
        label: "Displacement",
    },
];

export const animals_type = [
    {
        value: "land",
        label: "Land",
    },
    {
        value: "air",
        label: "Air",
    },
    {
        value: "water",
        label: "water",
    },
    {
        value: "insects",
        label: "insects",
    },
    {
        value: "pets",
        label: "pets",
    },
];

export const animals_cause = [
    {
        value: "humane_societies",
        label: "Humane Societies",
    },
    {
        value: "veterinary_services",
        label: "Veterinary Services",
    },
    {
        value: "protecting_from_animal_cruelty",
        label: "Protecting from animal cruelty",
    },
    {
        value: "training_and_education",
        label: "Training & Education",
    },
    {
        value: "abuse_and_violence",
        label: "Abuse & Violence",
    },
    {
        value: "endangered_species",
        label: "Endangered species",
    },
    {
        value: "wildlife_refuges_and_sanctuaries",
        label: "Wildlife refuges & sanctuaries",
    },
    {
        value: "zoos_and_aquariums",
        label: "Zoos & aquariums",
    },
    {
        value: "animal_rights_advocacy",
        label: "Animal rights advocacy",
    },
];

export const enviroment_area = [
    {
        value: "area_land",
        label: "Land",
    },
    {
        value: "area_air",
        label: "Air",
    },
    {
        value: "area_water",
        label: "Water",
    },
];

export const enviroment_cause = [
    {
        value: "clean_energy",
        label: "Clean energy/resources",
    },
    {
        value: "environmental_education",
        label: "Environmental Education",
    },
    {
        value: "protecting_habitats",
        label: "Protecting Habitats",
    },
    {
        value: "deforestation",
        label: "Deforestation",
    },
    {
        value: "environmental_cleanup",
        label: "Environmental Cleanup",
    },
    {
        value: "pollution",
        label: "Pollution",
    },
    {
        value: "recycling",
        label: "Recycling",
    },
    {
        value: "environmental_advocacy",
        label: "Environmental Advocacy",
    },
    {
        value: "oceanic_wildlife",
        label: "Oceanic Wildlife",
    },
    {
        value: "lakes_rivers_and_streams",
        label: "Lakes, Rivers & Streams",
    },
    {
        value: "global_warming",
        label: "Global Warming",
    },
];