import React from 'react';
import styles from './Mobile.module.css';

export const MobileMenu = ({ children, show, onClose }) => {

    const handleOutClick = (e) => e.stopPropagation();

    return (
        <div className={`${styles.modal} ${show ? styles.show : ''}`} onClick={onClose}>
            <div className={styles.modal_container} onClick={handleOutClick}>
                {children}
            </div>
        </div>
    )
}

export default MobileMenu;