import React from 'react'

export const Cause = (props) => {

    const { cause, setCause, setStep } = props;

    const onSelected = (option, value) => {
        let count = 0;
        for (const property in cause) {
            if (cause[property] === true) {
                count++;
            }
        }
        if (count >= 3) {
            setCause({
                ...cause,
                [option]: false,
            });
        } else {
            setCause({
                ...cause,
                [option]: !value,
            });
        }
    }

    const nextStep = () => {
        let count = 0;
        for (const property in cause) {
            if (cause[property] === true) {
                count++;
            }
        }
        if (count >= 1 && count <= 3) {
            setStep(4);
        }
    }

    const backStep = () => {
        setStep(2);
    }


    return (
        <div className="content">

            <h1 className="question">Select up to 3 causes.</h1>

            <div className="multiple-options">
                <div className={`option ${cause.education === true ? 'active' : ''}`}
                    onClick={() => onSelected('education', cause.education)}
                >Education</div>
                <div className={`option ${cause.food_and_water === true ? 'active' : ''}`}
                    onClick={() => onSelected('food_and_water', cause.food_and_water)}
                >Food &amp; Water</div>
                <div className={`option ${cause.healthcare === true ? 'active' : ''}`}
                    onClick={() => onSelected('healthcare', cause.healthcare)}
                >Healthcare</div>
                <div className={`option ${cause.community_development === true ? 'active' : ''}`}
                    onClick={() => onSelected('community_development', cause.community_development)}
                >Community Development</div>
                <div className={`option ${cause.bullying_and_violence === true ? 'active' : ''}`}
                    onClick={() => onSelected('bullying_and_violence', cause.bullying_and_violence)}
                >Bullying &amp; Violence</div>
                <div className={`option ${cause.minorities_and_racial_injustice === true ? 'active' : ''}`}
                    onClick={() => onSelected('minorities_and_racial_injustice', cause.minorities_and_racial_injustice)}
                >Supporting Minorities/Racial Justice</div>
                <div className={`option ${cause.lgbtqia === true ? 'active' : ''}`}
                    onClick={() => onSelected('lgbtqia', cause.lgbtqia)}
                >LGBTQIA+</div>
                <div className={`option ${cause.military_and_police === true ? 'active' : ''}`}
                    onClick={() => onSelected('military_and_police', cause.military_and_police)}
                >Military &amp; Police</div>
                <div className={`option ${cause.women === true ? 'active' : ''}`}
                    onClick={() => onSelected('women', cause.women)}
                >Women</div>
                <div className={`option ${cause.disaster_recovery === true ? 'active' : ''}`}
                    onClick={() => onSelected('disaster_recovery', cause.disaster_recovery)}
                >Disaster Recovery</div>
                <div className={`option ${cause.human_trafficking === true ? 'active' : ''}`}
                    onClick={() => onSelected('human_trafficking', cause.human_trafficking)}
                >Human Trafficking</div>
                <div className={`option ${cause.displacement === true ? 'active' : ''}`}
                    onClick={() => onSelected('displacement', cause.displacement)}
                >Displacement</div>
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
