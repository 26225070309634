import React from 'react';
import styles from './PRLogo.module.css';

export const PRLogo = () => {
    return (
        <div className={styles.cont_pr_logos}>
            <div className={styles.content}>
                {/* <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span>
                <span className={styles.text_pr_logo}>PR logos</span> */}
            </div>
        </div>
    )
}
