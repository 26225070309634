import React from 'react';
import WorldMap from 'components/common/WorldMap/WordMap.js';

export const Location = (props) => {

    const {locations, setLocations, setStep} = props;

    const saveLocation = (list) => {
        let continents = {};
        list.forEach(continent => {
            if(continent === "af"){
                continents.africa = true;
            }
            if(continent === "na"){
                continents.north_america = true;
            }
            if(continent === "sa"){
                continents.south_america = true;
            }
            if(continent === "eu"){
                continents.europe = true;
            }
            if(continent === "as"){
                continents.asia = true;
            }
            if(continent === "oc"){
                continents.australia = true;
            }
        });
        setLocations(continents);
    }

    const nextStep = () => {

        let change = false;
        for (const property in locations) {
            if(locations[property] === true){
                change = true;
            }
        }
        if(change){
            setStep(3);
        }

    }

    const backStep = () => {
        setStep(1);
    }

    return (
        <div className="content">

            <div>
                <h1 className="question">Do you want to help people in a certain area?</h1>
                <h1 className="text_apply">Pick as many continents as you’d like.</h1>
            </div>

            <div style={{ marginTop: 30 }}>
               <WorldMap getLocations={saveLocation} locations={locations} />
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
