import React from 'react'
import styles from './CardCharity.module.css';
import { ASSETS_SERVER } from 'services/Server';

export const CardCharity = (props) => {

    const { charity, bgPostion } = props;
    const { charity_picture_filename: image } = charity;

    const customStyles = {
        cardImg: {
            backgroundImage: "url('" + ASSETS_SERVER+image + "')",
            backgroundSize: 'cover',
            backRepeat: 'no-repeat',
            backgroundPosition: bgPostion ? bgPostion : '50% 50%',
        }
    }

    return (
        <div className={`${styles.charity_card}`}>
            <div className={`${styles.card_img}`} style={ image ? customStyles.cardImg: null}></div>
            <div className={`${styles.card_content}`}>
                <span className={`${styles.card_title}`}>{charity.charity_name}</span>
                <span className={`${styles.location}`}>{charity.charity_hq_city ? charity.charity_hq_city + ", " : ''} { charity.charity_hq_state ? charity.charity_hq_state + ", " : '' } { charity.charity_hq_country}</span>
                <span className={`${styles.card_description}`}>{charity.charity_description}</span>
            </div>
            <div className={`${styles.card_actions}`}>
                <a className={`${styles.btn_action}`} href={charity.charity_url} target="_blank">Website</a>
                <a className={`${styles.btn_action}`} href={charity.charity_donate_url} target="_blank">Donate</a>
            </div>
        </div>
    )
}

export default CardCharity;
