import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import Loader from "react-loader-spinner";
//Componets
import { CardCharity } from 'components/common/CardCharity/CardCharity';
import { Sidebar } from './sidebar/Sidebar';
//Hooks
import { useModal } from 'hooks/useModal';
import { usePagination } from 'hooks/usePagination';
//Inital State
import { initialState } from './initialState';
//Icons
import { VscSettings } from "react-icons/vsc";
//API
import RequestApi from 'services/RequestApi';
//Styles
import styles from './FindYourCharity.module.css';
//images
import bgCharity from 'assets/images/findYourCharity/findYourCharity.webp';
import MobileFilters from 'components/pages/findYourCharity/mobile/MobileFilters/MobileFilters.js';
import { PaginationRow } from './paginationContent/PaginationRow';

const customStyles = {
    bgOverlay: {
        position: 'absolute',
        backgroundImage: `url(${bgCharity})`,
        backgroundSize: 'cover',
        backRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        width: '100%',
        height: 717,
        zIndex: 1,
    }
}

export const FindYourCharity = (props) => {

    const { searchParam } = useParams();
    let param = searchParam ? decodeURI(searchParam): '';
   
    //State
    const [search, setSearch] = useState(param);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);

    //hooks
    const { show, openModal, closeModal } = useModal(false);
    const { results, pageCount, handlePageClick, setItems } = usePagination(10);

    useEffect(() => {

        // Get Data From API
        getCharityInformation(search, filters);

    }, [search, filters]);

    const getCharityInformation = async (search = "", filters) => {

        setLoading(true);

        let filters_active = {};
        let params = {
            search: search,
        }

        for (const property in filters) {
            if (filters[property] === true) {
                filters_active = { ...filters_active, [property]: filters[property] };
            }
        }

        params.filters = filters_active;

        try {
            const result = await axios.post('charityInformation', params);
            setItems(result.data);
        } catch (error) {
            console.log('Error: ', error);
            setItems([]);
        }

        setLoading(false);
    }

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const onFilter = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.checked,
        });
    }

    const cleanSearch = () => setSearch('');

    return (
        <>
            <div className={styles.container}>
                <div className={styles.view}>
                    <Sidebar search={search} onSearch={onSearch} onFilter={onFilter} filters={filters} />
                    <div className={styles.content}>

                        <div style={customStyles.bgOverlay}></div>

                        <div className={styles.head_content}>
                            <h1 className={styles.title}>Find your Charity</h1>
                            <p className={styles.description}>You’re in complete control—use the filters to narrow down the list of charities.</p>
                            <div className="content-center">
                                <button className={styles.btn_mobile_filter} onClick={openModal} ><VscSettings style={{ fontSize: 25, transform: 'rotate(90deg)' }} /> View All Filters</button>
                            </div>
                        </div>

                        <div className={styles.content_center}>

                            {loading ? (
                                <Loader
                                    type="Oval"
                                    color="#FFF"
                                    height={80}
                                    width={80}
                                />
                            ) : (
                                <div className={styles.charity_list}>
                                    {results && results.map((charity, index) => (
                                        <CardCharity key={index} charity={charity} />
                                    ))}
                                </div>
                            )}

                        </div>

                    </div>
                </div>
                <PaginationRow
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                />
            </div>

            <MobileFilters
                show={show}
                onClose={closeModal}
                filters={filters}
                setFilters={setFilters}
                cleanSearch={cleanSearch}
            />
        </>
    )
}
