import React from 'react';
//Components
import { CardList } from './cardList/CardList';
import { PRLogo } from './prLogo/PRLogo';
import { Intro } from './intro/Intro';
import { Testimonials } from './testimonials/Testimonials';

export const Home = () => {

    return (
        <div className="column">

                <Intro/>

                <PRLogo/>

                <CardList/>

                {/* <Testimonials/> */}

        </div>
    )
}
