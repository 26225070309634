import { Pagination } from 'components/common/Pagination/Pagination';
import React from 'react';
import styles from './PaginationRow.module.css';

export const PaginationRow = (props) => {

    return (
        <div className={styles.row}>
            <div className={styles.content}>
                <Pagination {...props} />
            </div>
        </div>
    )
}
