import React from 'react';
import { CardAnswer } from 'components/common/CardAnswer/CardAnswer';
//Images
import landImg from 'assets/images/questionnaire/enviroment/land.webp';
import airImg from 'assets/images/questionnaire/enviroment/air.webp';
import waterImg from 'assets/images/questionnaire/enviroment/water.webp';
//Styles
import styles from './Area.module.css';

export const Area = (props) => {

    const {area, setArea, setStep, resetQuiz} = props;

    const onSelected = (option, value) => {
        setArea({
            ...area,
            [option]: !value,
        });
    }

    const nextStep = () => {
        let change = false;
        for (const property in area) {
            if(area[property] === true){
                change = true;
            }
        }
        if(change){
            setStep(2);
        }
    }

    const backStep = () => {
        resetQuiz();
    }

    return (
        <div className="container">

            <div>
                <h1 className="question">What area do you want to support?</h1>
                <h1 className="text_apply">Select all that apply.</h1>
            </div>

            <div className={styles.card_list}>
                <CardAnswer title={'Land'} image={landImg} active={area.area_land} styles={styles} onClick={ () => onSelected('area_land', area.area_land) }  />
                <CardAnswer title={'Air'} image={airImg} active={area.area_air} styles={styles}  onClick={ () => onSelected('area_air', area.area_air) } />
                <CardAnswer title={'Water'} image={waterImg} active={area.area_water} styles={styles}  onClick={ () => onSelected('area_water', area.area_water) } />
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
