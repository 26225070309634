import React, {useState, useEffect} from 'react'
import { Results } from '../../results/Results';
import { AnimalType } from './questions/AnimalType';
import { Cause } from './questions/Cause';

export const AnimalsQuiz = (props) => {

    const { resetQuiz } = props;

    const [step, setStep] = useState(1);
    const [animalType, setAnimalType] = useState({});
    const [cause, setCause] = useState({});
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const cleanState = () => {
        //clean Animal Type
        setAnimalType({});

        //clean cause options
        setCause({});

        //Reset the Step
        setStep(1);

        //Reset Quiz
        resetQuiz()
    }

    return (
        <>
            {step === 1 ? (
                <AnimalType animalType={animalType} setAnimalType={setAnimalType} setStep={setStep} {...props} />
            ) : null}
            {step === 2 ? (
                <Cause cause={cause} setCause={setCause} setStep={setStep} />
            ) : null}
            {step === 3 ? (
                <Results filters={{...animalType, ...cause}} setStep={setStep} cleanState={cleanState} />
            ) : null}
        </>
    )
}

export default AnimalsQuiz;