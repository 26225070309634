import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Pagination.module.css';

export const Pagination = (props) => {

    const { pageCount, handlePageClick } = props;

    return (
        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName={styles.pagination}
                activeClassName={styles.active}
                activeLinkClassName={styles.active}
                pageLinkClassName={styles.link}
                nextLinkClassName={styles.link}
                previousLinkClassName={styles.link}
                breakLinkClassName={styles.link}
            />
        </>
    )
}

export default Pagination;