import React, { useState, useEffect } from 'react';
import axios from '../../../../utils/axios';
import { CardCharity } from 'components/common/CardCharity/CardCharity';
import styles from './Results.module.css';
import Loader from "react-loader-spinner";
//hooks
import { usePagination } from 'hooks/usePagination';
import Pagination from 'components/common/Pagination/Pagination';

export const Results = (props) => {

    //props
    const { filters, cleanState } = props;

    //state
    const [loading, setLoading] = useState(true);

    //hooks
    const { results, pageCount, handlePageClick, setItems } = usePagination(10);

    useEffect(() => {
        getCharityInformation(filters);
    }, [filters]);

    const getCharityInformation = async (filters) => {

        setLoading(true);

        let params = {};
        for (const property in filters) {
            if (filters[property] === true) {
                params = { ...params, [property]: filters[property] };
            }
        }

        try {
            const result = await axios.post('charityInformation', { filters: params });
            setItems(result.data);
        } catch (error) {
            console.log('Error: ', error);
            setItems([]);
        }

        setLoading(false);
    }

    return (
        <div className="content">

            <div className={styles.results}>

                <h1 className="question">We think these charities are a good fit for you</h1>

                <span className={styles.btn_retake} style={{ marginTop: 40 }} onClick={cleanState}>Retake the Quiz</span>

                <div className={styles.charity_list}>
                    {loading ? (
                        <Loader
                            type="Oval"
                            color="#144460"
                            height={80}
                            width={80}
                        />
                    ) : (
                        <>
                            {results && results.map((charity, index) => (
                                <CardCharity key={index} charity={charity} />
                            ))}
                        </>
                    )}
                </div>

                <Pagination
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                />

                <span className={styles.btn_retake} style={{ marginTop: 40 }} onClick={cleanState}>Retake the Quiz</span>

            </div>

        </div>
    )
}
