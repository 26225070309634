import React from 'react';
import { CardAnswer } from '../../../common/CardAnswer/CardAnswer';
//Images
import peopleImg from 'assets/images/questionnaire/typeQuiz/people.webp';
import animalsImg from 'assets/images/questionnaire/typeQuiz/animals.webp';
import enviromentImg from 'assets/images/questionnaire/typeQuiz/enviroment.webp';
//Styles
import styles from './QuizType.module.css';

export const QuizType = (props) => {

    const { selectOption } = props;

    return (
        <div className="container">
            
            <div>
                <h1 className="question">How will you change the world?</h1>
            </div>

            <div className={styles.card_list}>
                <CardAnswer title={'People'} image={peopleImg} styles={styles} onClick={() => selectOption('people')} />
                <CardAnswer title={'Animals'} image={animalsImg} styles={styles} onClick={() => selectOption('animals')} />
                <CardAnswer title={'Enviroment'} image={enviromentImg} styles={styles} onClick={() => selectOption('enviroment')} />
            </div>
        </div>
    )
}
