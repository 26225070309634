import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Card.module.css';

export const Card = (props) => {

    const { title, description, image, link, bgPostion } = props;

    const navigate = useNavigate();

    const customStyles = {
        cardImage: {
            backgroundImage: "url('" + image + "')",
            backgroundSize: 'cover',
            backRepeat: 'no-repeat',
            backgroundPosition: bgPostion ?? '50% 50%',
        },
    }

    const handleClick = () =>{
        navigate(`findYourCharity/${encodeURI(title)}`);
    }

    return (
            <div className={`${styles.card}`}>
                <div className={`${styles.card_img}`} style={customStyles.cardImage} onClick={handleClick}></div>
                <div className={`${styles.card_body}`}>
                    <span className={`${styles.card_title}`} onClick={handleClick}>{title}</span>
                </div>
                <div className={`${styles.card_actions}`}>
                    <Link
                        to={{
                            pathname: `findYourCharity/${encodeURI(title)}`,
                        }}
                        className={`${styles.btn_learn_more}`}
                    >Learn More</Link>
                </div>
            </div>
    )
}
