import React from 'react';
import { Dropdown } from '../dropdown/Dropdown';
import styles from '../Sidebar.module.css';

export const AnimalsFilters = (props) => {

    const { filters, onFilter } = props;

    const animals_type = [
        {
            name: "land",
            value: filters.land ?? false,
            text: "Land",
        },
        {
            name: "air",
            value: filters.air ?? false,
            text: "Air",
        },
        {
            name: "water",
            value: filters.water ?? false,
            text: "water",
        },
        {
            name: "insects",
            value: filters.insects ?? false,
            text: "insects",
        },
        {
            name: "pets",
            value: filters.pets ?? false,
            text: "pets",
        },
    ];

    const animals_cause = [
        {
            name: "humane_societies",
            value: filters.humane_societies ?? false,
            text: "Humane Societies",
        },
        {
            name: "veterinary_services",
            value: filters.veterinary_services ?? false,
            text: "Veterinary Services",
        },
        {
            name: "protecting_from_animal_cruelty",
            value: filters.protecting_from_animal_cruelty ?? false,
            text: "Protecting from animal cruelty",
        },
        {
            name: "training_and_education",
            value: filters.training_and_education ?? false,
            text: "Training & Education",
        },
        {
            name: "abuse_and_violence",
            value: filters.abuse_and_violence ?? false,
            text: "Abuse & Violence",
        },
        {
            name: "endangered_species",
            value: filters.endangered_species ?? false,
            text: "Endangered species",
        },
        {
            name: "wildlife_refuges_and_sanctuaries",
            value: filters.wildlife_refuges_and_sanctuaries ?? false,
            text: "Wildlife refuges & sanctuaries",
        },
        {
            name: "zoos_and_aquariums",
            value: filters.zoos_and_aquariums ?? false,
            text: "Zoos & aquariums",
        },
        {
            name: "animal_rights_advocacy",
            value: filters.animal_rights_advocacy ?? false,
            text: "Animal rights advocacy",
        },
    ];

    return (
        <>
            <h2 className={styles.list_type_name}>Animals</h2>
            <ul className={`${styles.charity_type_list}`}>
                <Dropdown
                    title={'Animal type'}
                    onFilter={onFilter}
                    values={animals_type}
                />
                <Dropdown
                    title={'Cause'}
                    onFilter={onFilter}
                    values={animals_cause}
                />
            </ul>
        </>
    )
}
