import React from 'react'
import missionImg from 'assets/images/mission/mission.webp';
//Styles
import './Mission.css';
import { Link } from 'react-router-dom';

export const Mission = () => {

    const styles = {
        darkOverlay: {
            position: 'absolute',
            width: "100%",
            height: 792,
            background: "rgba(3, 3, 3, 0.5)",
        },
        intro: {
            width: "100%",
            height: 792,
            backgroundImage: `url(${missionImg})`,
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
        }
    }

    return (
        <div className="mission" >
            <div className="intro" style={styles.intro}>
                <div style={styles.darkOverlay}></div>
                <div className="content">
                    <h1 className="title">Mission</h1>
                    <p className="description">
                        CharityLabs helps humans give more intentionally.
                        <br />
                        <br />
                        People are good. But how do they find causes that match their interests and life experience? How do they select a charity that truly fulfills its own mission? How do people evaluate the impact of their giving?
                        <br />
                        <br />
                        We seek to answer these questions.
                    </p>
                </div>
            </div>

            <div className="sec-floor-pledge">
                <div className="content">
                    <h2 className="title"><a href="https://www.97thfloor.com" target="_blank" >97th Floor</a> &amp; Pledge 1%</h2>
                    <p className="description">
                        CharityLabs was built by <a className='text-bold brand-link' href="https://www.97thfloor.com" target="_blank">97th Floor</a> —a Marketing agency for high-performing enterprises, founded in 2005. While launching massive-scale marketing campaigns has been our business, it has never been our mission. In collaboration with our friends at Salesforce, Atlassian, 6sense and more, 97th Floor is a Pledge 1% organization—giving 1% of all profits to charitable causes and organizations.
                        <br />
                        <br />
                        For years, 97th Floor employees have selected charities to receive their company-sponsored donations, giving the team the opportunity to learn about new causes, find greater empathy, and gain awareness of brave organizations driving change in the world.
                        <br />
                        <br />
                        CharityLabs is the next stage of this tradition.
                    </p>
                </div>
            </div>

            <div className="sec-list-charities">
                <div className="content">
                    <h1 className="title">Where did we get our list of Charities?</h1>
                    <p className="description">
                        After including our employees’ selected charities, we went to work trying to understand impact—no easy feat considering there are dozens of metrics, models and studies all dedicated to finding charities that truly cause positive change.
                        <br />
                        <br />
                        The charities found here on CharityLabs each met certain criteria including evidence of results, cost effectiveness, financial transparency, communication of mission and beneficiaries, and accountability. That said, we are eager to learn about more charities and give the world greater visibility into the great work down around the globe. If you don’t see a charity you love on our list, head over to <Link className='text-bold brand-link' to='/submitCharity'>Submit a Charity</Link>.
                    </p>
                </div>
            </div>

        </div>
    )
}
