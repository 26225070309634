import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
//Pages
import { About } from 'components/pages/about/About';
import { ContactUs } from 'components/pages/contactUs/ContactUs';
import { FindYourCharity } from 'components/pages/findYourCharity/FindYourCharity';
import { Home } from 'components/pages/home/Home';
import { Questionnaire } from 'components/pages/questionnaire/Questionnaire';
import { SubmitCharity } from 'components/pages/submitCharity/SubmitCharity';
import { Mission } from 'components/pages/mission/Mission';
import { Error404 } from 'components/pages/error404/Error404';
//UI
import { Navbar } from 'components/ui/navbar/Navbar';
import { Footer } from 'components/ui/footer/Footer';
//Screen at the top
import { ScrollReset } from 'components/common/ScrollReset/ScrollReset.js';

export const AppRouter = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/findYourCharity" element={<FindYourCharity />} >
                    <Route path=":searchParam" element={<FindYourCharity />} />
                </Route>
                <Route exact path="/mission" element={<Mission />} />
                <Route exact path="/submitCharity" element={<SubmitCharity />} />
                <Route exact path="/questionnaire" element={<Questionnaire />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contactUs" element={<ContactUs />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
            <Footer/>
        </>
    )
}

export default AppRouter;