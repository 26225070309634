import React,{useState} from 'react'
import { CardAnswer } from 'components/common/CardAnswer/CardAnswer';
//Images
import landImg from 'assets/images/questionnaire/animals/land.webp';
import airImg from 'assets/images/questionnaire/animals/air.webp';
import waterImg from 'assets/images/questionnaire/animals/water.webp';
import insectsImg from 'assets/images/questionnaire/animals/insects.webp';
import petsImg from 'assets/images/questionnaire/animals/pets.webp';
//Styles
import styles from './Animals.module.css';

export const AnimalType = (props) => {

    const {animalType, setAnimalType, setStep, resetQuiz} = props;

    const onSelected = (option, value) => {
        setAnimalType({
            ...animalType,
            [option]: !value,
        });
    }

    const nextStep = () => {
        let change = false;
        for (const property in animalType) {
            if(animalType[property] === true){
                change = true;
            }
        }
        if(change){
            setStep(2);
        }
    }

    const backStep = () => {
        resetQuiz();
    }

    return (
        <div className="container">

            <div>
                <h1 className="question">Do you want to help a certain type of animal?</h1>
                <h1 className="text_apply">Select all that apply.</h1>
            </div>

            <div className={styles.card_list}>
                <CardAnswer title={'Land'} image={landImg} active={animalType.land} styles={styles} onClick={ () => onSelected('land', animalType.land) }  />
                <CardAnswer title={'Air'} image={airImg} active={animalType.air} styles={styles}  onClick={ () => onSelected('air', animalType.air) } />
                <CardAnswer title={'Water'} image={waterImg} active={animalType.water} styles={styles}  onClick={ () => onSelected('water', animalType.water) } />
                <CardAnswer title={'Insects'} image={insectsImg} active={animalType.insects} styles={styles}  onClick={ () => onSelected('insects', animalType.insects) } />
                <CardAnswer title={'Pets'} image={petsImg} active={animalType.pets} styles={styles}  onClick={ () => onSelected('pets', animalType.pets) } />
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
