import React, { useState } from 'react';
import styles from './Dropdown.module.css';
import {MdKeyboardArrowUp, MdKeyboardArrowRight} from "react-icons/md";

export const Dropdown = (props) => {

    const { title, values, onFilter } = props;

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <li className={styles.item}>
            <span className={styles.dropdown_title} onClick={handleClick}>{click ? <MdKeyboardArrowUp/> : <MdKeyboardArrowRight/> } {title}</span>
            {click === true ? (
                <ul className={styles.charity_type_list}>
                    {values.map((row, i) => (
                        <li key={i} className={styles.dropdown_item}>
                            <label className={styles.check_item}><input type="checkbox" name={row.name} onChange={onFilter} checked={row.value} /> {row.text}</label>
                        </li>
                    ))}
                </ul>
            ) : null}
        </li>
    );
}
