import React, { useState } from 'react';
import MobileMenu from 'components/common/MobileMenu/MobileMenu.js';
import styles from './MobileFilters.module.css';
import { PeopleFilterMobile } from './filters/PeopleFilterMobile';
import { AnimalsFilterMobile } from './filters/AnimalsFilterMobile';
import { EnviromentFilterMobile } from './filters/EnviromentFilterMobile';

export const MenuFilters = (props) => {

    const { show, onClose, filters, setFilters, cleanSearch } = props;

    const [filterType, setFilterType] = useState('');
    const handleFilterType = (value) => setFilterType(value);

    const applyFilters = (values) => {

        let data = {};
        values.forEach(el => {
            data = { ...data, ...{ [el.value]: true } };
        });

        cleanSearch();
        setFilters(data);
        onClose();

    }
    const resetFilters = () => {
        setFilterType('');
        setFilters({});
        cleanSearch();
        onClose();
    }

    let filters_clean = {};
    for (const property in filters) {
        if (filters[property] === true) {
            filters_clean = { ...filters_clean, ...{ [property]: filters[property] } }
        }
    }

    return (
        <MobileMenu show={show} onClose={onClose}>

            <div className={styles.content}>
                <h1 className={styles.filter_title}>Filters</h1>
                <span className={styles.filter_question}>How do you want to change the world?</span>

                <div className={styles.cont_actions}>
                    <button
                        className={`${styles.btn_filter} ${filterType === 'people' ? styles.active : ''}`}
                        onClick={() => handleFilterType('people')}>People</button>
                    <button
                        className={`${styles.btn_filter} ${filterType === 'animals' ? styles.active : ''}`}
                        onClick={() => handleFilterType('animals')}>Animals</button>
                    <button
                        className={`${styles.btn_filter} ${filterType === 'enviroment' ? styles.active : ''}`}
                        onClick={() => handleFilterType('enviroment')}>Enviroment</button>
                </div>

                {filterType === 'people' ? (
                    <PeopleFilterMobile
                        applyFilters={applyFilters}
                        resetFilters={resetFilters}
                        filters={filters_clean}
                    />
                ) : null}
                {filterType === 'animals' ? (
                    <AnimalsFilterMobile
                        applyFilters={applyFilters}
                        resetFilters={resetFilters}
                        filters={filters_clean}
                    />
                ) : null}
                {filterType === 'enviroment' ? (
                    <EnviromentFilterMobile
                        applyFilters={applyFilters}
                        resetFilters={resetFilters}
                        filters={filters_clean}
                    />
                ) : null}

            </div>

        </MobileMenu>
    )
}

export default MenuFilters;