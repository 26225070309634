import React from 'react';
import componentStyles from './CardAnswer.module.css';

export const CardAnswer = (props) => {

    const { title, active, image, onClick, styles: propStyles, bgPostion } = props;

    const customStyles = {
        cardImg: {
            backgroundImage: "url('" + image + "')",
            backgroundSize: 'cover',
            backRepeat: 'no-repeat',
            backgroundPosition: bgPostion ? bgPostion : '50% 50%',
        }
    }

    const styles = propStyles ? propStyles : componentStyles;

    return (
        <div className={`${styles.card} ${active === true ? styles.active : ''}`} onClick={onClick} >
            <div className={`${styles.card_img}`} style={customStyles.cardImg} ></div>
            <div className={`${styles.card_content}`}>
                <h2 className={`${styles.card_title}`}>{title}</h2>
            </div>
        </div>
    );
}

export default CardAnswer;