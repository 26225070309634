import React from 'react'
import { Dropdown } from '../dropdown/Dropdown';
import styles from '../Sidebar.module.css';

export const PeopleFilters = (props) => {

    const { filters, onFilter } = props;

    const people_ages = [
        {
            name: "children",
            value: filters.children ?? false,
            text: "Children",
        },
        {
            name: "teenagers",
            value: filters.teenagers ?? false,
            text: "Teenagers",
        },
        {
            name: "adults",
            value: filters.adults ?? false,
            text: "Adults",
        },
        {
            name: "elderly",
            value: filters.elderly ?? false,
            text: "Elderly",
        },
    ];

    const people_locations = [
        {
            name: "north_america",
            value: filters.north_america ?? false,
            text: "North America",
        },
        {
            name: "south_america",
            value: filters.south_america ?? false,
            text: "South America",
        },
        {
            name: "europe",
            value: filters.europe ?? false,
            text: "Europe",
        },
        {
            name: "africa",
            value: filters.africa ?? false,
            text: "Africa",
        },
        {
            name: "asia",
            value: filters.asia ?? false,
            text: "Asia",
        },
        {
            name: "australia",
            value: filters.australia ?? false,
            text: "Australia",
        },
    ];

    const people_cause = [
        {
            name: "education",
            value: filters.education ?? false,
            text: "Education",
        },
        {
            name: "food_and_water",
            value: filters.food_and_water ?? false,
            text: "Food & Water",
        },
        {
            name: "healthcare",
            value: filters.healthcare ?? false,
            text: "Healthcare",
        },
        {
            name: "community_development",
            value: filters.community_development ?? false,
            text: "Community Development",
        },
        {
            name: "bullying_and_violence",
            value: filters.bullying_and_violence ?? false,
            text: "Bullying and Violence",
        },
        {
            name: "minorities_and_racial_injustice",
            value: filters.minorities_and_racial_injustice ?? false,
            text: "Minority/racial justice",
        },
        {
            name: "lgbtqia",
            value: filters.lgbtqia ?? false,
            text: "LGBTQIA",
        },
        {
            name: "military_and_police",
            value: filters.military_and_police ?? false,
            text: "Military & Police",
        },
        {
            name: "women",
            value: filters.women ?? false,
            text: "Women",
        },
        {
            name: "disaster_recovery",
            value: filters.disaster_recovery ?? false,
            text: "Disaster Recovery",
        },
        {
            name: "human_trafficking",
            value: filters.human_trafficking ?? false,
            text: "Human Trafficking",
        },
        {
            name: "displacement",
            value: filters.displacement ?? false,
            text: "Displacement",
        },
    ];

    return (
        <>
            <h2 className={styles.list_type_name}>People</h2>
            <ul className={`${styles.charity_type_list}`}>
                <Dropdown
                    title={'Ages'}
                    onFilter={onFilter}
                    values={people_ages}
                />
                <Dropdown
                    title={'Locations'}
                    onFilter={onFilter}
                    values={people_locations}
                />
                <Dropdown
                    title={'Cause'}
                    onFilter={onFilter}
                    values={people_cause}
                />
            </ul>
        </>
    )
}
