import React from 'react';

export const Cause = (props) => {

    const { cause, setCause, setStep } = props;

    const onSelected = (option, value) => {
        let count = 0;
        for (const property in cause) {
            if (cause[property] === true) {
                count++;
            }
        }
        if (count >= 3) {
            setCause({
                ...cause,
                [option]: false,
            });
        } else {
            setCause({
                ...cause,
                [option]: !value,
            });
        }
    }

    const nextStep = () => {
        let count = 0;
        for (const property in cause) {
            if (cause[property] === true) {
                count++;
            }
        }
        if (count >= 1 && count <= 3) {
            setStep(3);
        }
    }

    const backStep = () => {
        setStep(1);
    }

    return (
        <div className="content" >

            <h1 className="question">Select up to 3 causes.</h1>

            <div className="multiple-options">
                <div className={`option ${cause.humane_societies === true ? 'active' : ''}`}
                    onClick={() => onSelected('humane_societies', cause.humane_societies)}
                >Humane Societies</div>
                <div className={`option ${cause.veterinary_services === true ? 'active' : ''}`}
                    onClick={() => onSelected('veterinary_services', cause.veterinary_services)}
                >Veterinary Services</div>
                <div className={`option ${cause.protecting_from_animal_cruelty === true ? 'active' : ''}`}
                    onClick={() => onSelected('protecting_from_animal_cruelty', cause.protecting_from_animal_cruelty)}
                >Protect from Animal Cruelty</div>
                <div className={`option ${cause.training_and_education === true ? 'active' : ''}`}
                    onClick={() => onSelected('training_and_education', cause.training_and_education)}
                >Training &amp; Education</div>
                <div className={`option ${cause.abuse_and_violence === true ? 'active' : ''}`}
                    onClick={() => onSelected('abuse_and_violence', cause.abuse_and_violence)}
                >Abuse &amp; Violence</div>
                <div className={`option ${cause.endangered_species === true ? 'active' : ''}`}
                    onClick={() => onSelected('endangered_species', cause.endangered_species)}
                >Endangered Species</div>
                <div className={`option ${cause.wildlife_refuges_and_sanctuaries === true ? 'active' : ''}`}
                    onClick={() => onSelected('wildlife_refuges_and_sanctuaries', cause.wildlife_refuges_and_sanctuaries)}
                >Wildlife Refuges &amp; Sanctuaries</div>
                <div className={`option ${cause.zoos_and_aquariums === true ? 'active' : ''}`}
                    onClick={() => onSelected('zoos_and_aquariums', cause.zoos_and_aquariums)}
                >Zoos &amp; Aquariums</div><div className={`option ${cause.animal_rights_advocacy === true ? 'active' : ''}`}
                    onClick={() => onSelected('animal_rights_advocacy', cause.animal_rights_advocacy)}
                >Animal Rights Advocacy</div>
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
