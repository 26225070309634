import React from 'react';
import styles from '../Sidebar.module.css';
import { Dropdown } from '../dropdown/Dropdown';

export const EnviromentFilters = (props) => {

    const { filters, onFilter } = props;

    const enviroment_area = [
        {
            name: "area_land",
            value: filters.area_land ?? false,
            text: "Land",
        },
        {
            name: "area_air",
            value: filters.area_air ?? false,
            text: "Air",
        },
        {
            name: "area_water",
            value: filters.area_water ?? false,
            text: "Water",
        },
    ];

    const enviroment_cause = [
        {
            name: "clean_energy",
            value: filters.clean_energy ?? false,
            text: "Clean energy/resources",
        },
        {
            name: "environmental_education",
            value: filters.environmental_education ?? false,
            text: "Environmental Education",
        },
        {
            name: "protecting_habitats",
            value: filters.protecting_habitats ?? false,
            text: "Protecting Habitats",
        },
        {
            name: "deforestation",
            value: filters.deforestation ?? false,
            text: "Deforestation",
        },
        {
            name: "environmental_cleanup",
            value: filters.environmental_cleanup ?? false,
            text: "Environmental Cleanup",
        },
        {
            name: "pollution",
            value: filters.pollution ?? false,
            text: "Pollution",
        },
        {
            name: "recycling",
            value: filters.recycling ?? false,
            text: "Recycling",
        },
        {
            name: "environmental_advocacy",
            value: filters.environmental_advocacy ?? false,
            text: "Environmental Advocacy",
        },
        {
            name: "oceanic_wildlife",
            value: filters.oceanic_wildlife ?? false,
            text: "Oceanic Wildlife",
        },
        {
            name: "lakes_rivers_and_streams",
            value: filters.lakes_rivers_and_streams ?? false,
            text: "Lakes, Rivers & Streams",
        },
        {
            name: "global_warming",
            value: filters.global_warming ?? false,
            text: "Global Warming",
        },
    ];

    return (
        <>
            <h2 className={styles.list_type_name}>Enviroment</h2>
            <ul className={`${styles.charity_type_list}`}>
                <Dropdown
                    title={'Area'}
                    onFilter={onFilter}
                    values={enviroment_area}
                />
                <Dropdown
                    title={'Cause'}
                    onFilter={onFilter}
                    values={enviroment_cause}
                />
            </ul>
        </>
    )
}
