import React, { useState } from 'react';
import axios from 'utils/axios';
import bgOvelayImg from 'assets/images/submitCharity/submitCharity.webp';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
//Styles
import './SubmitCharity.css';

const styles = {
    darkOverlay: {
        position: 'absolute',
        width: "100%",
        height: "100%",
        background: "rgba(3, 3, 3, 0.5)",
        zIndex: 1,
        height: 769,
    },
    bgOverlay: {
        zIndex: 0,
        position: 'absolute',
        backgroundImage: `url(${bgOvelayImg})`,
        backgroundSize: 'cover',
        backRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        width: '100%',
        height: 769,
    }
}

const validationsSchema = Yup.object().shape({
    charity_name: Yup.string().required('Charity Name is a required field'),
    charity_mission: Yup.string().required('Charity Mission is a required field'),
    website_url: Yup.string().url('Must be a valid URL').required('Website URL is a required field'),
    contact_email: Yup.string().email('Invalid email').required('Contact Email Address is a required field'),
    contact_name: Yup.string().required('Contact Email Address is a required field'),
    donation_url: Yup.string().url('Must be a valid URL').required('Charity Donation Page URL is a required field'),
});

export const SubmitCharity = () => {

    //State
    const [loading, setloading] = useState(false);

    //Formik
    const formik = useFormik({
        initialValues: {
            charity_name: "",
            charity_mission: "",
            website_url: "",
            contact_name: "",
            contact_email: "",
            donation_url: "",
        },
        validationSchema: validationsSchema,
        onSubmit: async (values, { resetForm }) => {

            //loading status
            setloading(true);

            try {
                const result = await axios.post('charity/submitCharity', values);

                if (result.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Request Sent!',
                        text: result.data.message,
                    });
                    resetForm({});
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: result.data.message,
                    });
                }
            } catch (error) {
                console.log('Error: ', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                });
            }

            //loading status
            setloading(false);

        },
    });

    return (
        <div className="submit-charity">
            <div style={styles.darkOverlay}></div>
            <div style={styles.bgOverlay}></div>
            <div className="content">
                <div className="cont-left">
                    <h1 className="title">Submit Charity</h1>
                    <p className="description">
                        Not seeing your favorite charity in our database? It’s probably because we’re just getting started. Fill out the form and we’ll add you to our Charity review queue.
                    </p>
                </div>
                <div className="cont-right">
                    <form className="form-charity" onSubmit={formik.handleSubmit}>
                        <input type="text" name="charity_name"
                            className="charity-input"
                            onChange={formik.handleChange}
                            value={formik.values.charity_name}
                            placeholder="Charity Name"
                        />
                        {formik.errors.charity_name && formik.touched.charity_name ? (
                            <div className="msg-error">{formik.errors.charity_name}</div>
                        ) : null}
                        <textarea name="charity_mission"
                            className="charity-text-area"
                            onChange={formik.handleChange}
                            value={formik.values.charity_mission}
                            placeholder="Charity Mission"
                        ></textarea>
                        {formik.errors.charity_mission && formik.touched.charity_mission ? (
                            <div className="msg-error">{formik.errors.charity_mission}</div>
                        ) : null}
                        <input type="text"
                            name="website_url"
                            className="charity-input"
                            onChange={formik.handleChange}
                            value={formik.values.website_url}
                            placeholder="Website URL"
                        />
                        {formik.errors.website_url && formik.touched.website_url ? (
                            <div className="msg-error">{formik.errors.website_url}</div>
                        ) : null}
                        <input type="text"
                            name="contact_name"
                            className="charity-input"
                            onChange={formik.handleChange}
                            value={formik.values.contact_name}
                            placeholder="Contact First Name &amp; Last Name"
                        />
                        {formik.errors.contact_name && formik.touched.contact_name ? (
                            <div className="msg-error">{formik.errors.contact_name}</div>
                        ) : null}
                        <input type="text"
                            name="contact_email"
                            onChange={formik.handleChange}
                            value={formik.values.contact_email}
                            className="charity-input field-error"
                            placeholder="Contact Email Address"
                        />
                        {formik.errors.contact_email && formik.touched.contact_email ? (
                            <div className="msg-error">{formik.errors.contact_email}</div>
                        ) : null}
                        <input type="text"
                            name="donation_url"
                            className="charity-input"
                            onChange={formik.handleChange}
                            value={formik.values.donation_url}
                            placeholder="Charity Donation page URL"
                        />
                        {formik.errors.donation_url && formik.touched.donation_url ? (
                            <div className="msg-error">{formik.errors.donation_url}</div>
                        ) : null}
                        <button className="btn-submit" disabled={loading}>
                            {loading ? (
                                <Loader
                                    type="ThreeDots"
                                    color="#FFF"
                                    height={30}
                                    width={50}
                                />
                            ) : ('Submit')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
