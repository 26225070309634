import React from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
//Styles
import styles from './Intro.module.css';
//images
import helpGenerousImg from 'assets/images/home/intro.webp';

const customStyles = {
    bgHelp: {
        backgroundImage: "url('" + helpGenerousImg + "')",
        backgroundSize: 'cover',
        backRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
    }
}

export const Intro = (props) => {

    const { } = props;

    return (
        <div className={styles.intro}>
            <div className={styles.intro_content}>
            <div className={styles.content}>
                <div className={styles.cont_left}>
                    <h1 className={styles.title }>Helping humans give more intentionally</h1>
                    <span className={styles.description}>There are more than 1.5 million charitable organizations in the United States alone. CharityLabs is the first charity-matching engine built to identify the right causes for you and your charitable goals.</span>
                </div>
                <Link to="/questionnaire" className={styles.btn_quiz}>Take the Charity Quiz</Link>
                <div className={styles.cont_search}>
                    <Link to="/findYourCharity" className={styles.link_search}><AiOutlineSearch /> Search Charities Directly</Link>
                </div>
            </div>
            <div className={styles.intro_img} style={customStyles.bgHelp} alt="Helping generous humans give more intentionally." />
        </div>
        </div>
    )
}
