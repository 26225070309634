import React from 'react';
import { CardAnswer } from 'components/common/CardAnswer/CardAnswer';
//Styles
import styles from './Age.module.css';
//Images
import childrenImg from 'assets/images/questionnaire/people/children.webp';
import teenagersImg from 'assets/images/questionnaire/people/teenagers.webp';
import adutlsImg from 'assets/images/questionnaire/people/adults.webp';
import elderlyImg from 'assets/images/questionnaire/people/elderly.webp';

export const Age = (props) => {

    const { age, setAge, setStep, resetQuiz } = props;

    const onSelected = (option, value) => {
        setAge({
            ...age,
            [option]: !value,
        });
    }

    const nextStep = () => {
        let change = false;
        for (const property in age) {
            if(age[property] === true){
                change = true;
            }
        }
        if(change){
            setStep(2);
        }
    }

    const backStep = () => {
        resetQuiz();
    }

    return (
        <div className="container">

            <div>
                <h1 className="question">Do you want to help people of a certain age?</h1>
                <h1 className="text_apply">Select all that apply.</h1>
            </div>

            <div className={styles.card_list}>
                <CardAnswer title={'Children'} image={childrenImg} active={age.children} styles={styles} onClick={ () => onSelected('children', age.children) }  />
                <CardAnswer title={'Teenagers'} image={teenagersImg } active={age.teenagers} styles={styles} bgPostion={'50% 10%'} onClick={ () => onSelected('teenagers', age.teenagers) } />
                <CardAnswer title={'Adults'} image={adutlsImg} active={age.adults} styles={styles} onClick={ () => onSelected('adults', age.adults) } />
                <CardAnswer title={'Elderly'} image={elderlyImg} active={age.elderly} styles={styles} bgPostion={'100% 50%'} onClick={ () => onSelected('elderly', age.elderly) } />
            </div>

            <div className="cont_actions">
                <button className="btn-quiz" onClick={backStep}>Back</button>
                <button className="btn-quiz" onClick={nextStep}>Next</button>
            </div>

        </div>
    )
}
