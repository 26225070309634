import React, {useState , useEffect} from 'react'
import { Results } from '../../results/Results';
import { Area } from './questions/Area';
import { Cause } from './questions/Cause';

export const EnviromentQuiz = (props) => {

    const { resetQuiz } = props;

    const [step, setStep] = useState(1);
    const [area, setArea] = useState({});
    const [cause, setCause] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const cleanState = () => {
        //clean Animal Type
        setArea({});

        //clean cause options
        setCause({});

        //Reset the Step
        setStep(1);

        //Reset Quiz
        resetQuiz()
    }

    return (
        <>
            {step === 1 ? (
                <Area area={area} setArea={setArea} setStep={setStep} {...props} />
            ) : null}
            {step === 2 ? (
                <Cause cause={cause} setCause={setCause} setStep={setStep} />
            ) : null}
            {step === 3 ? (
                <Results filters={{...area, ...cause}} setStep={setStep} cleanState={cleanState} />
            ) : null}
        </>
    )
}

export default EnviromentQuiz;